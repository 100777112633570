import React from 'react';
import PropTypes from 'prop-types';
import CssBaseline from '@material-ui/core/CssBaseline';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

const theme = createMuiTheme({
  overrides: {
    MuiCssBaseline: {
      '@global': {
        html: {
          WebkitFontSmoothing: 'auto',
        },
        a: {
          boxShadow: 'none',
          color: 'green',
          textDecoration: 'none'
        },
      },
    },
  },
  typography: {
    htmlFontSize: 16,
    fontFamily: [
      'Noto Sans JP',
      'Sawarabi Mincho'
    ].join(','),
  },
  h1: {
    fontFamily: 'Sawarabi Mincho',
    fontSize: '4.2em',
    '@media (min-width:600px)': {
      fontSize: '65%'
    },
  },
  h2: {
    fontFamily: 'Sawarabi Mincho',
    fontSize: '3.7em',
    '@media (min-width:600px)': {
      fontSize: '1rem',
    },
  },
  h3: {
    fontFamily: 'Sawarabi Mincho',
    fontSize: '3em',
    '@media (min-width:600px)': {
      fontSize: '2.3rem',
    },
  },
  h4: {
    fontFamily: 'Sawarabi Mincho',
    fontSize: '2.3em',
    '@media (min-width:600px)': {
      fontSize: '1.9rem',
    },
  },
  h5: {
    fontFamily: 'Sawarabi Mincho',
    fontSize: '1.9em',
    '@media (min-width:600px)': {
      fontSize: '1.5rem',
    },
  },
  h6: {
    fontFamily: 'Sawarabi Mincho',
    fontSize: '1em',
    '@media (min-width:600px)': {
      fontSize: '.5rem',
    },
  },
});

export default function MuiTheme(props) {
  return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {props.children}
      </ThemeProvider>
  );
}

MuiTheme.propTypes = {
  children: PropTypes.node,
};