module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-loadable-components-ssr/gatsby-browser.js'),
      options: {"plugins":[],"useHydrate":true},
    },{
      plugin: require('../plugins/et-mui-theme/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[],"stylesProvider":{"injectFirst":true}},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-119238155-1","head":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"EdwinTeaches.com","short_name":"Edwin Teaches","description":"An American educator living in Japan","lang":"en","display":"standalone","icon":"src/images/logo.svg","icons":[{"src":"favicons/logo-192.png","sizes":"192x192","type":"image/png"},{"src":"favicons/logo-512.png","sizes":"512x512","type":"image/png"}],"start_url":"/","background_color":"#ffffff","theme_color":"#073f0c","theme_color_in_head":false,"crossOrigin":"use-credentials","cache_busting_mode":"none","include_favicon":true,"legacy":true,"cacheDigest":null},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
